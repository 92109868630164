<template>
  <div class="page-cu-container">
    <div class="page-cu-top">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <div class="page-cu-main">
      <vuescroll>
        <a-table
          :columns="columns"
          :data-source="list"
          rowKey="id"
          bordered
          :pagination="false"
        >
          <template slot="state" slot-scope="text, record">
            <a-tag color="green" v-if="text == '1'">启用</a-tag>
            <a-tag color="orange" v-if="text == '2'">禁用</a-tag>
          </template>
           <template slot="is_audit_state" slot-scope="text, record">
            <a-tag color="green" v-if="text == '1'">需要审核</a-tag>
            <a-tag color="orange" v-if="text == '2'">无需审核</a-tag>
          </template>
          <template slot="endTime" slot-scope="text, record">
            <a-tag
              color="red"
              v-if="new Date(text).valueOf() < new Date().valueOf()"
            >
              <a-icon type="info-circle" /> {{ text }}
            </a-tag>
            <span v-else>{{ text }}</span>
          </template>
          <!-- <span slot="action" slot-scope="text, record">
            <a @click="editAction(record)"><a-icon type="edit" /> 编辑</a>
            <a-divider type="vertical" />
            <a @click="delConfirm(record.id)" class="text-red"
              ><a-icon type="close" />删除</a
            >
          </span> -->
          <template slot="action" slot-scope="text, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                更多操作 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item @click="() => editAction(record['id'])">
                  <a-button type="link" size="small" icon="edit">编辑</a-button>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item @click="() => delConfirm(record['id'])">
                  <a-button type="link" size="small" icon="delete"
                    >删除</a-button
                  >
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item
                  v-if="record['state'] == '1'"
                  @click="
                    () => enabledState(record['id'], 2, '你确定要禁用吗？')
                  "
                >
                  <a-button type="link" size="small" icon="minus-circle"
                    >禁用</a-button
                  >
                </a-menu-item>
                <a-menu-item
                  v-if="record['state'] == '2'"
                  @click="enabledState(record['id'], 1, '你确定要启用吗？')"
                >
                  <a-button type="link" size="small" icon="check-circle"
                    >启用</a-button
                  >
                </a-menu-item>
                <a-menu-item
                  v-if="record['is_audit_state'] == '1'"
                  @click="
                    () => toexamine(record['id'], 2, '你确定要操作为无需审核吗？')
                  "
                >
                  <a-button type="link" size="small" icon="minus-circle"
                    >无需审核</a-button
                  >
                </a-menu-item>
                <a-menu-item
                  v-if="record['is_audit_state'] == '2'"
                  @click="toexamine(record['id'], 1, '你确定要操作为需要审核吗？')"
                >
                  <a-button type="link" size="small" icon="check-circle"
                    >需要审核</a-button
                  >
                </a-menu-item>
                <a-menu-item @click="() => changeServiceEndTime(record['id'])">
                  <a-button type="link" size="small" icon="schedule"
                    >设置服务结束时间</a-button
                  >
                </a-menu-item>
                <a-menu-item @click="() => openWechatData(record['id'])">
                  <a-button type="link" size="small" icon="wechat"
                    >设置微信小程序</a-button
                  >
                </a-menu-item>

                <a-menu-divider />
              </a-menu>
            </a-dropdown>
          </template>
        </a-table>
        <div class="page-cu-pagination">
          <a-pagination
            :page-size.sync="page.pageSize"
            :total="page.totalRow"
            v-model="page.start"
            @change="changePage"
          />
        </div>
      </vuescroll>
    </div>

    <a-modal
      title="新增"
      :visible="actionVisible"
      @ok="actionData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="formModel"
        :rules="formModelRules"
        :model="formModel"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="公司名称" prop="name">
          <a-input
            v-model="formModel.name"
            placeholder="请输入公司名称"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="公司电话" prop="mobile">
          <a-input
            v-model="formModel.mobile"
            placeholder="请输入公司电话"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="应急联系人" prop="contact_name">
          <a-input
            v-model="formModel.contact_name"
            placeholder="请输入应急联系人"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="应急联系手机" prop="contact_mobile">
          <a-input
            v-model="formModel.contact_mobile"
            placeholder="请输入应急联系手机"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="注册地址" prop="registration_address">
          <a-input
            v-model="formModel.registration_address"
            placeholder="请输入注册地址"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="服务开始时间"
          prop="service_start_time"
          v-if="isAdd"
        >
          <a-date-picker
            style="width: 100%"
            v-model="formModel.service_start_time"
            :disabled-date="disabledStartDate"
            value-format="YYYY-MM-DD"
            placeholder="请选择服务开始时间"
          />
        </a-form-model-item>
        <a-form-model-item
          label="服务结束时间"
          prop="service_end_time"
          v-if="isAdd"
        >
          <a-date-picker
            style="width: 100%"
            v-model="formModel.service_end_time"
            :disabled-date="disabledEndDate"
            value-format="YYYY-MM-DD"
            placeholder="请选择服务结束时间"
          />
        </a-form-model-item>
        <a-form-model-item label="登录账号" prop="login_account" v-if="isAdd">
          <a-input
            v-model="formModel.login_account"
            placeholder="请输入登录账号"
            autocomplete="off"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="登录密码" prop="login_pwd" v-if="isAdd">
          <a-input
            v-model="formModel.login_pwd"
            type="password"
            placeholder="请输入登录密码"
            autocomplete="off"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      title="设置服务结束日期"
      :visible="UpdateTimeVisible"
      @ok="UpdateTimeData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="UpdateTimeForm"
        :rules="UpdateTimeRules"
        :model="UpdateTime"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="公司名称" prop="name">
          <a-input v-model="UpdateTime.name" disabled></a-input>
        </a-form-model-item>
        <a-form-model-item label="服务开始时间" prop="service_start_time">
          <a-date-picker
            style="width: 100%"
            v-model="UpdateTime.service_start_time"
            :disabled-date="disabledStartDate2"
            value-format="YYYY-MM-DD"
            placeholder="请选择服务开始时间"
            disabled
          />
        </a-form-model-item>
        <a-form-model-item label="服务结束时间" prop="service_end_time">
          <a-date-picker
            style="width: 100%"
            v-model="UpdateTime.service_end_time"
            :disabled-date="disabledEndDate2"
            value-format="YYYY-MM-DD"
            placeholder="请选择服务结束时间"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      title="设置微信小程序"
      :visible="WechatVisible"
      @ok="UpdateWechatData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="UpdateWechatForm"
        :rules="WechatRules"
        :model="WechatData"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="公司名称" prop="name">
          <a-input v-model="WechatData.name" disabled></a-input>
        </a-form-model-item>

        <a-form-model-item label="小程序appID" prop="app_id">
          <a-input v-model="WechatData.app_id"></a-input>
        </a-form-model-item>

        <a-form-model-item label="小程序appsecret" prop="app_secret">
          <a-input v-model="WechatData.app_secret"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      isAdd: true, // TODO: 是否是新增操作
      formModel: {
        id: "",
        name: "",
        mobile: "",
        contact_name: "",
        contact_mobile: "",
        registration_address: "",
        service_start_time: null,
        service_end_time: null,
        login_account: "",
        login_pwd: "",
      },
      UpdateTimeVisible: false,
      UpdateTime: {
        id: "",
        name: "",
        service_start_time: "",
        service_end_time: "",
      },
      formModelRules: {
        name: [
          { required: true, message: "请输入公司名称", trigger: "change" },
        ],
        service_start_time: [
          { required: true, message: "请选择服务开始时间", trigger: "change" },
        ],
        service_end_time: [
          { required: true, message: "请选择服务结束时间", trigger: "change" },
        ],
        login_account: [
          { required: true, message: "请输入登录账号", trigger: "change" },
        ],
        login_pwd: [
          { required: true, message: "请输入登录密码", trigger: "change" },
        ],
      },
      WechatVisible: false,
      UpdateTimeRules: {
        service_end_time: [
          { required: true, message: "请选择服务结束时间", trigger: "change" },
        ],
      },
      WechatRules: {
        app_id: [
          { required: true, message: "请输入小程序appID", trigger: "change" },
        ],
        app_secret: [
          {
            required: true,
            message: "请输入小程序appsecret",
            trigger: "change",
          },
        ],
      },
      WechatData: {
        id: "",
        name: "",
        app_id: "",
        app_secret: "",
      },
      columns: [
        {
          title: "公司名称",
          dataIndex: "name",
        },
        {
          title: "公司电话",
          dataIndex: "mobile",
        },
        {
          title: "应急联系人",
          dataIndex: "contact_name",
        },
        {
          title: "应急联系电话",
          dataIndex: "contact_mobile",
        },
        {
          title: "注册地址",
          dataIndex: "registration_address",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "小程序appID",
          dataIndex: "app_id",
        },
        {
          title: "小程序appsecret",
          dataIndex: "app_secret",
        },
        {
          title: "服务开始时间",
          dataIndex: "service_start_time",
        },
        {
          title: "服务结束时间",
          dataIndex: "service_end_time",
          scopedSlots: { customRender: "endTime" },
        },
        {
          title: "状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "员工入场审核",
          width:120,
          dataIndex: "is_audit_state",
          scopedSlots: { customRender: "is_audit_state" },
        },
        {
          title: "操作",
          width: 150,
          scopedSlots: { customRender: "action" },
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        type: "",
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * 添加，更新
     *
     */
    actionData() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let res = null;
          console.log(this.formModel);
          if (this.formModel["id"]) {
            res = await Api.Update(this.formModel);
          } else {
            res = await Api.Save(this.formModel);
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.actionVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 更新服务结束时间
     *
     */
    UpdateTimeData() {
      this.$refs.UpdateTimeForm.validate(async (valid) => {
        if (valid) {
          let params = {
            id: this.UpdateTime.id,
            service_end_time: this.UpdateTime.service_end_time,
          };
          let res = await Api.ChangeServiceEndTime(params);
          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.UpdateTimeVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 设置微信小程序
     *
     */
    UpdateWechatData() {
      this.$refs.UpdateWechatForm.validate(async (valid) => {
        if (valid) {
          let params = {
            id: this.WechatData.id,
            app_id: this.WechatData.app_id,
            app_secret: this.WechatData.app_secret,
          };
          let res = await Api.ChangeWechat(params);
          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.WechatVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },

    /*
     * 表单重置
     **/
    resetFormModel() {
      for (let key in this.formModel) {
        this.formModel[key] = "";
      }
    },
    // 菜单选择
    handlerCheck(n, e) {
      this.checkedKeys = { checked: n, halfChecked: e.halfCheckedKeys };
    },
    // 打开添加公司窗
    addAction() {
      this.isAdd = true;
      this.resetFormModel();
      this.actionVisible = true;
    },

    // 编辑
    async editAction(id) {
      this.isAdd = false;
      this.resetFormModel();
      let params = {
        id: id,
      };
      let res = await Api.DetailMenu(params);
      Object.keys(this.formModel).forEach((key) => {
        this.formModel[key] = res.target[key];
      });
      this.actionVisible = true;
    },

    // 设置服务结束时间
    async changeServiceEndTime(id) {
      let params = {
        id: id,
      };
      let res = await Api.DetailMenu(params);
      Object.keys(this.UpdateTime).forEach((key) => {
        this.UpdateTime[key] = res.target[key];
      });
      this.UpdateTimeVisible = true;
    },

    // 设置小程序弹框
    async openWechatData(id) {
      let params = {
        id: id,
      };
      let res = await Api.DetailMenu(params);
      Object.keys(this.WechatData).forEach((key) => {
        this.WechatData[key] = res.target[key];
      });
      this.WechatVisible = true;
    },

    // 关闭窗口
    cancel() {
      this.actionVisible = false;
      this.UpdateTimeVisible = false;
      this.WechatVisible = false;
    },

    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({
        start: this.page.start,
        limit: this.page.limit,
        key: this.page.key,
      });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.AdminEnabled(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    
    // 审核
    toexamine(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            is_audit_state: state,
          };
          let res = await Api.examine(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },

    // TODO: 开始结束时间限制
    disabledStartDate(startValue) {
      const endValue = this.formModel.service_end_time;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.formModel.service_start_time;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    // 设置服务结束时间时
    disabledStartDate2(startValue) {
      const endValue = this.UpdateTime.service_end_time;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate2(endValue) {
      const startValue = this.UpdateTime.service_start_time;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
  },
};
</script>

<style scoped>
</style>

