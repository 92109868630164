import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/custom/company/page',
        method: 'GET',
        params
    });
}

// 保存
export function Save(data) {
    return request({
        url: '/admin/custom/company/save',
        method: 'POST',
        data
    });
}

// 更新
export function Update(data) {
    return request({
        url: '/admin/custom/company/update',
        method: 'POST',
        data
    });
}

// 详情
export function DetailMenu(params) {
    return request({
        url: '/admin/custom/company/detail',
        method: 'GET',
        params
    });
}

// 删除
export function Delete(params) {
    return request({
        url: '/admin/custom/company/delete',
        method: 'GET',
        params
    });
}

/**
 * 启用/禁用
 */
export function AdminEnabled(params) {
    return request({
        url: '/admin/custom/company/changeIndex',
        method: 'GET',
        params
    });
}
/**
 * 审核
 */
 export function examine(params) {
    return request({
        url: '/admin/custom/company/isAuditState',
        method: 'GET',
        params
    });
}

/**
 * 设置服务结束时间
 */
export function ChangeServiceEndTime(data) {
    return request({
        url: '/admin/custom/company/changeServiceEndTime',
        method: 'POST',
        data
    });
}

/**
 * 设置微信小程序
 */
export function ChangeWechat(data) {
    return request({
        url: '/admin/custom/company/changeWxApp',
        method: 'POST',
        data
    });
}

